<template>
  <div class="page">
    <div class="bg-white" style="margin-bottom: 10px">
      <el-tabs v-model="favoritesType" size="small" @tab-click="search(1)">
        <el-tab-pane :label="'资源'+'('+countInfo.resource+')'" name="2"></el-tab-pane>
        <el-tab-pane :label="'专题库'+'('+countInfo.library+')'" name="3"></el-tab-pane>
        <el-tab-pane :label="'藏品'+'('+countInfo.collection+')'" name="1"></el-tab-pane>
        <el-tab-pane :label="'档案'+'('+countInfo.archives+')'" name="0"></el-tab-pane>
      </el-tabs>
    </div>
    <div v-show="favoritesType == 0 || favoritesType == 1">
      <collection1 :favoritesType="favoritesType" :pageType="pageType" ref="collection1" @refresh="getCount()"></collection1>
    </div>
    <div v-show="favoritesType == 2 || favoritesType == 3">
      <collection-resources :favorites="favoritesType" :pageType="pageType" ref="collectionResources" @refresh="getCount()"></collection-resources>
    </div>
  </div>
</template>

<script>
import Collection1 from "@/views/modules/personal/collect/collection1.vue";
import CollectionResources from "@/views/modules/personal/collect/collectionResources.vue";

export default {
  props: ['pageType'],
  components: {
    CollectionResources,
    Collection1,
  },
  data() {
    return {
      favoritesType: '2',
      countInfo: {
        archives: 0,
        collection: 0,
        resource: 0,
        library: 0,
      },
    }
  },

  mounted() {
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
    if (listSearch) {
      console.log(listSearch)
      this.favoritesType = listSearch.favoritesType
      if(this.favoritesType == 3){
        this.$refs.collectionResources.favoritesType = 1
      }
      sessionStorage.removeItem('listSearch')
    }
    this.getCount()
  },
  methods: {
    search(){
      if (this.favoritesType == 0 || this.favoritesType == 1){
        this.$refs.collection1.search(1,this.favoritesType)
      }else {
        this.$refs.collectionResources.search(this.favoritesType)
      }
    },

    // 获取总数
    getCount() {
      this.$axios(this.api.record.getArchivesUtilizeCount, {
        favoritesCollection: this.pageType == 'admin' ? '0':'1'
      }).then((res) => {
        if (res.status) {
          this.countInfo.archives = res.data
        }
      })
      this.$axios(this.api.collection.collGectiongetArchivesUtilizeCount, {
        favoritesCollection: this.pageType == 'admin' ? '0':'1'
      }).then((res) => {
        if (res.status) {
          this.countInfo.collection = res.data
        }
      })

      let identification = '1'
      if(this.pageType === 'admin'){
        identification = '0'
      }
      this.$axios(this.api.dyyg.myCollectionNum, {
        thematicName: '',
        thematicType: '',
        identification:identification
      }, 'get').then(res => {
        if (res.status) {
          this.countInfo.library = res.data
        }
      })

      this.$axios(this.api.dyyg.homeCollectionList, {
        'current': 1,
        'size': 1,
        'name': '',
        'favoritesType': '',
        identification:identification
      }, 'get').then(res => {
        if (res.status) {
          this.countInfo.resource = parseInt(res.data.total);
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
